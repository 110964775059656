// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import { getPhrasesByPageAndLanguage } from '../constants/languagePhrases';
const langKey = 'claimComplete';

export default class ClaimComplete extends React.Component {

    state = {
        phrases: {},
    }

    componentDidMount = async () => {
        
        if (!this.props.user){
            if (await this.props.tryLogin()) {
                this.props.history.push("/login")
                return null
            }
        }

        if (this.props.user.flagged)
            this.props.history.push("/")

        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
        
        window.scrollTo(0, 0);

    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language) 
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    render = () => {
     
        if (!this.props.user)
            return null

        return (<div className="container">

                <h1>{this.state.phrases.msgThatsIt}</h1>
                <h3>{this.state.phrases.msgWhatsNext}</h3>

                <p>{this.state.phrases.msgWeReceivedPaperwork}</p>
                
                <h3>{this.state.phrases.msgHereForYou}</h3>

                <div className="phone">
                    <h1><a href='tel:18007764243'>1-800-776-4243</a></h1>
                </div>        

            </div>);

    }

};