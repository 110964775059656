// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import api from '../api';

import AddAppInstructions from './AddAppInstructions';

import { getPhrasesByPageAndLanguage } from '../constants/languagePhrases';
const langKey = 'login';

const isMobile = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}

export default class Login extends React.Component {

    state = {
        email: "",
        password: "",

        attempted: false,
        matching: true,
        success: true,
        loggingIn: false,

        showHelp: false,
        helpPopup: true,
        showIOS: false,
        showAndroid: false,

        // language dictionary
        phrases: {},
    }

    componentDidMount = () =>  {
        this.props.logout();
        window.scrollTo(0, 0)

        if (window.matchMedia('(display-mode: standalone)').matches) {
            this.setState({helpPopup: false})
        }
        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language) 
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }
    
    attemptLogin = async () => {
        let res = await api.post("self/login", {email: this.state.email, password: this.state.password}).catch(() => {
            this.setState({success : false, loggingIn: false})
        })

        if (this.state.success) {

            // // // Temporary
            await this.props.setUser(res.user);

            this.props.history.push('/');

            return true;
        }

        else {
            return false;
        }
    }

    render = () => {

        if (this.state.showHelp) {
            return (
                <div>
                    
                    <AddAppInstructions language={this.props.language}/>

                    <button
                        style = {{
                            position: "fixed",
                            width: "60%",
                            bottom: "2%",
                            left: "20%"
                        }}
                        onClick={() => this.setState({showHelp: false})}
                    // >Return to Login
                    >{this.state.phrases.returnToLogin}
                    </button>

                </div>
            )
        }

        else {
            return (<>
                {isMobile() && this.state.helpPopup && <div className="addToHomescreen" onClick = {() => {
                    this.setState({showHelp: true})
                }}>
                    <strong>{this.state.phrases.btnClickToAddApp1}<br />{this.state.phrases.btnClickToAddApp2}</strong>
                </div>}
    
                <div className="nouser-container">
                    
                    <h1>{this.state.phrases.msgWelcome}</h1>
                    <h2>{this.state.phrases.msgLoginToAccount}</h2>
    
                    <label>{this.state.phrases.lblEmail}</label>
                    <input
                        type="text"
                        value={this.state.email}
                        //style={{"borderBottom" : this.state.success ? "4px solid rgb(230,230,230)" : "4px solid rgb(230,170,170)"}}
                        onChange={(e) => this.setState({ email: e.target.value.toLowerCase(), success: true })}
                        onKeyPress={e => {
                            if (e.key === 'Enter')
                                this.refs.loginButton.click();
                        }}
                    />
                    {!this.state.success ? <div className="invalid-text">{this.state.phrases.msgBadEmail}</div> : null}
    
                    <label>{this.state.phrases.lblPasswd}</label>
                    <input
                        type="password"
                        value={this.state.password}
                        onChange={(e) => this.setState({ password: e.target.value, success: true })}
                        onKeyPress={e => {
                            if (e.key === 'Enter')
                                this.refs.loginButton.click();
                        }}
                    />
    
                    {!this.state.matching ? <div className="invalid-text">{this.state.phrases.msgBadPasswd}</div> : null}
    
                    <div 
                        onClick={() => this.props.history.push('/forgot')}
                        style={{
                            cursor: 'pointer',
                            float: 'right',
                            marginTop: '0.5em',
                            fontSize: '11pt',
                            lineHeight: '1em',
                            color: 'var(--color-bright)'
                    }}>{this.state.phrases.btnForgot}</div>
    
                    {!this.state.loggingIn
                        ?   <button
                                ref='loginButton'
                                onClick={async () => {
                                    this.setState({loggingIn: true}, async () => {
                                        if ((await this.attemptLogin()) === false)
                                            this.setState({loggingIn: false})
                                    })
                                }}
                                style={{ width: '80%', marginLeft: '10%' }}
                            >{this.state.phrases.btnLogin}</button>
                        : <button style={{ width: '80%', marginLeft: '10%' }}>
                            Loading...
                        </button>
                    }
                    
    
                    <div style={{
                        textAlign: 'center',
                        marginTop: '20pt',
                        fontSize: '11pt',
                        paddingBottom: "10vw",
                        color: 'var(--color-text-medium)'
                    }}>
                        {this.state.phrases.msgNoAccount}
                        <a onClick={() => this.props.history.push("/welcome")}> {this.state.phrases.btnCreateNow}</a>
                    </div>
    
                </div>
            </>)
        }
    }

};