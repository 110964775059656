// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import { NavLink } from 'react-router-dom';
import Toggle from 'react-toggle';
import "react-toggle/style.css"

import {getPhrasesByPageAndLanguage} from '../constants/languagePhrases';
const langKey = 'template';

export default class Template extends React.Component {

    state = {
        navOpen: false,
        phrases: {},
    }

    componentDidMount = async () => {
        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language)
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    render = () => {

        return (<div className='templateComponent'>

            {this.state.navOpen && <div className='navBackdrop' onClick={() => this.setState({navOpen: false})}></div>}
            
            {/* Navigation menu */}
            {!window.location.href.match(/verify-email$/) && this.props.user && this.props.user._id ? <nav className={this.state.navOpen ? "nav-opened" : ""}>

                
                {/* Nav menu icon */}
                <img
                    src="/nav.png"
                    alt="Menu"
                    style={{zIndex:10}}
                    onClick={() => this.setState({ navOpen: !this.state.navOpen })}
                />

                {/* Navigation menu */}
                <div className="menu">

                    <NavLink
                        className="menu-item"
                        to="/"
                        onClick={() => {
                            this.props.closeMenu();
                            this.setState({navOpen: false});
                        }}
                    >{this.state.phrases.btnHome}</NavLink>

                    <NavLink
                        className="menu-item"
                        to="/new-claim"
                        onClick={() => {
                            this.props.closeMenu();
                            this.setState({navOpen: false});
                        }}
                    >{this.state.phrases.btnNewClaim}</NavLink>

                    <NavLink
                        className="menu-item"
                        to="/claims"
                        onClick={() => {
                            this.props.closeMenu();
                            this.setState({navOpen: false});
                        }}
                    >{this.state.phrases.btnMyClaims}</NavLink>

                    <NavLink
                        className="menu-item"
                        to="/account-settings"
                        onClick={() => {
                            this.props.closeMenu();
                            this.setState({navOpen: false});
                        }}
                    >{this.state.phrases.btnMyAccount}</NavLink>

                    <NavLink
                        className="menu-item"
                        to="/support"
                        onClick={() => {
                            this.props.closeMenu();
                            this.setState({navOpen: false});
                        }}
                    >{this.state.phrases.btnSupport}</NavLink>

                    <NavLink
                        className="menu-item"
                        to="/login"
                        onClick={() => {
                            this.props.logout();
                            this.props.closeMenu();
                            this.setState({navOpen: false});
                        }}
                    >{this.state.phrases.btnLogOut}</NavLink>

                </div>

            </nav> : ''}

            {/* FBC Icon */}
            <div
                className="logo-container"
                style={this.props.user && this.props.user._id ? {
                    // position: "fixed",
                    boxShadow: "0 0 10px 0 rgba(0,0,0,0.15)"
                } : {}}
                onClick={() => this.setState({navOpen: false})}
            >
                <img
                    src="/logo.png"
                    alt="FBC"
                    className={this.props.user && this.props.user._id ? "logo-small" : "logo-big"}
                />
            </div>

            <div className='languageToggleContainer'>
                {/* <label htmlFor='languageToggle'>Adjacent label tag</label> */}
                <Toggle
                    className='languageToggle'
                    checked={this.props.language == 'english'}
                    onChange={() => this.props.setLanguage(this.props.language == 'english' ? 'spanish' : 'english')} 
                    icons={{
                        checked: <span className='lblEnglish'>English</span>,
                        unchecked: <span className='lblSpanish'>Español</span>,
                    }}
                />
            </div>
            
        </div>)
    }

};