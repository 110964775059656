// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import { getPhrasesByPageAndLanguage } from '../constants/languagePhrases';
const langKey = 'addAppInstructions';

export default class AddAppInstructions extends React.Component {

    state = {
        showIOS: false,
        showAndroid: false,

        phrases: {},
    }

    componentDidMount = () => {
        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language)
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }


    render = () => {
        return (
            <div className="nouser-container">

                <p>{this.state.phrases.cmdNavigate}</p>

                <h1 style = {{paddingTop: "1vh"}} onClick={() => this.setState({showIOS: !this.state.showIOS})}>{(this.state.showIOS ? '▼ ' : '► ') + " Apple/iOS"}</h1>

                {this.state.showIOS && <p>{this.state.phrases.ios_msgUseSafari}</p>}

                {this.state.showIOS && <p>{this.state.phrases.ios_msgClickSendToBtn}</p>}

                {this.state.showIOS && <img
                    src="howToPWA/Group75.png"
                    alt="img1"
                    width="100%"
                    style={{paddingTop: "3vh"}}
                />}

                {this.state.showIOS && <p>{this.state.phrases.ios_msgHomeScreenBtn}</p>}

                {this.state.showIOS && <img
                    src="howToPWA/Group76.png"
                    alt="img2"
                    width="100%"
                    style={{paddingTop: "3vh"}}
                />}

                {this.state.showIOS && <p>{this.state.phrases.ios_msgPressAdd}</p>}

                {this.state.showIOS && <img
                    src="howToPWA/addHomeScreen.png"
                    alt="img3"
                    width="100%"
                    style={{paddingTop: "3vh"}}
                />}

                {this.state.showIOS && <p>{this.state.phrases.ios_msgNowAccessible}</p>}

                {this.state.showIOS && <img
                    src="howToPWA/Group78.png"
                    alt="img4"
                    width="100%"
                    style={{paddingTop: "3vh"}}
                />}

                <h1 onClick={() => this.setState({showAndroid: !this.state.showAndroid})}>{(this.state.showAndroid ? '▼ ' : '► ') + " Android"}</h1>

                {this.state.showAndroid && <p>{this.state.phrases.android_msgSomeBrowsers}</p>}

                {this.state.showAndroid && <p>{this.state.phrases.android_msgSelectSettings}</p>}

                {this.state.showAndroid && <img
                    src="howToPWA/Group79.png"
                    alt="img5"
                    width="100%"
                    style={{paddingTop: "3vh"}}
                    
                />}

                {this.state.showAndroid && <p>{this.state.phrases.android_msgHomeScreenBtn}</p>}

                {this.state.showAndroid && <img
                    src="howToPWA/Group80.png"
                    alt="img6"
                    width="100%"
                    style={{paddingTop: "3vh"}}
                />}

                {this.state.showAndroid && <p>{this.state.phrases.android_msgFollow}</p>}

                {this.state.showAndroid && <img
                    src="howToPWA/Group81.png"
                    alt="img6"
                    width="100%"
                    style={{paddingTop: "3vh"}}
                />}

                {this.state.showAndroid && <img
                    src="howToPWA/Group82.png"
                    alt="img7"
                    width="100%"
                    style={{paddingBottom: "50px", paddingTop: "3vh"}}
                />}

                {this.state.showAndroid && <p>{this.state.phrases.android_msgNowAccessible}</p>}

                {this.state.showAndroid && <img
                    src="howToPWA/Group83.png"
                    alt="img8"
                    width="100%"
                    style={{paddingBottom: "50px", paddingTop: "3vh"}}
                />}

            </div>
        )
    }
}