// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';


import '../styles/accountSettings.css'
import api from '../api';

import {getPhrasesByPageAndLanguage} from '../constants/languagePhrases';
const langKey = 'accountSettings';

export default class AccountSettings extends React.Component {
    
    state = {
        employer: null,
        plan: null,
        phrases: {},
    }

    componentDidMount = async () => {
        this.props.setLoadingState(true);
        if (!this.props.user){
            if (await this.props.tryLogin()) {
                this.props.history.push("/login")
                return null
            }
        }

        if (this.props.user.plan)
        {

            let plan = await api.get("self/getPlan")
            let employer = await api.get("self/getEmployer")

            this.setState({plan, employer})

        }

        this.props.setLoadingState(false);
        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
        window.scrollTo(0, 0);
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language)
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    render = () => {

        if (!this.props.user)
            return null

        return (
            <div className="container">
                <div className = "account-settings-container">
                    <h1 style={{margin:"0px"}}>{this.state.phrases.msgAccountSettings}</h1>
                    
                    {/* <img className='acct_editBtn' src="edit.svg" alt="edit icon" /> */}
                    <div className="account-settings-label">{this.state.phrases.lblFullName}</div>
                    <input 
                        type="text" readOnly={true} defaultValue={this.props.user.name}
                    />

                    {/* <img className='acct_editBtn' src="edit.svg" alt="edit icon" /> */}
                    <div className="account-settings-label">{this.state.phrases.lblPhoneNumber}</div>
                    <input 
                        type="text" readOnly={true} defaultValue={this.props.user.phone}
                    />

                    {/* <img className='acct_editBtn' src="edit.svg" alt="edit icon" /> */}
                    <div className="account-settings-label">{this.state.phrases.lblEmail}</div>
                    <input 
                        type="text" readOnly={true} defaultValue={this.props.user.email}
                    />

                    <div className="account-settings-label">{this.state.phrases.lblPassword}</div>
                    <input
                        type="text" readOnly={true} value='********' 
                    />

                    {/* <img className='acct_editBtn' src="edit.svg" alt="edit icon" /> */}
                    {/* <div className="account-settings-label">Employer</div>
                    <input 
                    type="text" readOnly={true} defaultValue={this.state.employer ? this.state.employer : ""}/> */}

                    {/* <img className='acct_editBtn' src="edit.svg" alt="edit icon" /> */}
                    {/* <div className="account-settings-label">Plan</div>
                    <input 
                    type="text" readOnly={true} defaultValue={this.state.plan ? this.state.plan.name : ""}/> */}
                     
                    <div className ="change-password-btn" 
                        onClick = {() => {this.props.history.push("/change-password")}}
                    >
                        {this.state.phrases.btnChangePassword}
                        <img src="orange-caret.svg" alt="arrow to change password" />
                    </div>

                    {/* <div 
                        className ="remove-plan-btn" 
                        onClick={async () => {
                            if (window.confirm('Are you sure you would like to delete this plan?')) {
                                this.props.setLoadingState(true);
                                await api.post("self/update", {diff : {employer: null, plan: null}});
                                await this.props.tryLogin();
                                window.location.reload(false);
                                this.props.setLoadingState(true);
                            }
                    }}>
                        Remove Plan
                        <img src="remove-plan.svg" alt="remove plan icon" />
                    </div> */}
                    
                </div> 

             </div>);


    }

};