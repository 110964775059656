// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import api from "../api";

import {getPhrasesByPageAndLanguage} from '../constants/languagePhrases';
const langKey = 'clientPlan';

export default class ClientPlan extends React.Component {

    state = {
        plan : null,
        planGraphic: null,
        phrases: {},
    }

    componentDidMount = async () => {
        this.props.setLoadingState(true);
        if (!this.props.user){
            if (await this.props.tryLogin()) {
                this.props.history.push("/login")
                return null
            }
        }

        if (this.props.user.flagged)
        {
            this.props.history.push("/")
            this.props.setLoadingState(false);
            return true
        }  

        if (!this.props.newClaimData.plan) {
            this.props.history.push("/")
            this.props.setLoadingState(false);
            return true
        }

        const planId = this.props.newClaimData.plan._id;

        let plan = await api.get("plan/get", {id: planId})
        if (plan.graphic) {
            let docStream = await api.get("plan/download", { id: plan._id, key: plan.graphic}, null, "blob")
            const blob = new Blob(
                [docStream],
                {type: "image/png"}
            )

            this.setState({plan, planGraphic : URL.createObjectURL(blob)})
        }

        else {
            this.setState({plan})
        }

        
        this.props.setLoadingState(false);
        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});

        window.scrollTo(0, 0);
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language)
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    render = () => {

        if (!this.props.user)
            return null

        return (<div className="container">

            <h1 className="your-plan">{this.state.plan ? this.state.plan.name : ''} {this.state.plan && this.state.plan.year ? '(' + this.state.plan.year + ')' : ''}</h1>

            <div className="plan">
                {this.state.planGraphic && <img 
                    src={this.state.planGraphic} 
                    width="80%" 
                    alt="plan graphic"
                    onClick={() => {
                        window.open(this.state.planGraphic)
                    }}
                />}
            </div>
            
            <h3>{this.state.phrases.msgPlanExplanation}</h3>
            <p>
                {this.state.plan ? this.state.plan.explanation : ''}
            </p>

            <button
                className="next-page"
                onClick={() => this.props.history.push('/new-claim')}
                onSubmit={() => this.props.history.push('/new-claim')}
                style={{ width: '40%', marginLeft: '5%' }}
            >{this.state.phrases.btnBack}
            </button>

            <button
                onClick={() => this.props.history.push('/document-capture')}
                onSubmit={() => this.props.history.push('/document-capture')}
                style={{ width: '40%', marginLeft: '10%' }}
            >{this.state.phrases.btnNext}
            </button>
        </div>);
    }
};