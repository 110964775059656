// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import "../style.css"
import api from '../api';
import '../styles/accountSettings.css'

import {getPhrasesByPageAndLanguage} from '../constants/languagePhrases';
const langKey = 'changePassword';

export default class AccountSettings extends React.Component {
    
    state = {
        currentPassword: "",
        newPassword : "",
        confirm: "",

        attempted: false,
        success: false,
        matching: true,
        phrases: {},
    }

    componentDidMount = async () => {
        if (!this.props.user){
            if (await this.props.tryLogin()) {
                this.props.history.push("/login")
                return null
            }
        }

        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
        
        window.scrollTo(0, 0)
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language)
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    checkMatching = () => {
        if (this.state.newPassword === this.state.confirm)
            this.setState({matching: true})
        else { this.setState({matching: false}) }
        
    }

    render = () => {

        if (!this.props.user)
            return null

        return (
            <div className="container">
                <div className = "account-settings-container">
                    <h1>{this.state.phrases.msgChangePassword}</h1>
                    
                    <img className='acct_editBtn' src="edit.svg" alt="edit icon" />
                    <div className="account-settings-label">{this.state.phrases.lblCurrentPassword}</div>
                    <input type="password" 
                        onKeyPress={e => {
                            if (e.key === 'Enter')
                                this.refs.submitButton.click();
                        }}
                        onChange={async e => {
                            this.setState({currentPassword: e.target.value, attempted: false}, this.checkMatching);
                    }}/>
                    
                    {/* Sucessfull or unsuccessful password change attempts */}
                    {this.state.attempted && !this.state.success ? <div className="invalid-text">{this.state.phrases.msgIncorrectPassword}</div> : null}
                    {this.state.attempted && this.state.success ? <div className="valid-text">{this.state.phrases.msgPasswordChanged}</div> : null}

                    <img className='acct_editBtn' src="edit.svg" alt="edit icon" />
                    <div className="account-settings-label">{this.state.phrases.lblNewPassword}</div>
                    <input type="password" 
                        onKeyPress={e => {
                            if (e.key === 'Enter')
                                this.refs.submitButton.click();
                        }}
                        onChange={async e => {
                            this.setState({newPassword: e.target.value, attempted: false}, this.checkMatching);
                    }}/>

                    <img className='acct_editBtn' src="edit.svg" alt="edit icon" />
                    <div className="account-settings-label">{this.state.phrases.lblRetypeNewPassword}</div>
                    <input type="password" 
                        onKeyPress={e => {
                            if (e.key === 'Enter')
                                this.refs.submitButton.click();
                        }}
                        onChange={async e => {
                            this.setState({confirm: e.target.value, attempted: false}, this.checkMatching);
                    }}/>

                    {!this.state.matching ? <div className="invalid-text">{this.state.phrases.msgPasswordsDontMatch}</div> : null}

                    <button
                        className="next-page"
                        onClick={async () => {
                            this.props.history.push("/account-settings")
                        }}
                        style={{ width: '40%', marginLeft: '5%', marginTop: "15%"}}
                        >{this.state.phrases.btnBack}</button>

                    {!this.state.success && this.state.matching && this.state.newPassword.length > 0 && this.state.currentPassword.length > 0 && <button
                        ref='submitButton'
                        onClick={async () => {
                            // Make sure the user actually filled out both fields
                            if (this.state.newPassword === "" || this.state.currentPassword === "") 
                                return;
                            
                            // Make sure the retyped passwords match
                            if (!this.state.matching) 
                                return;

                            this.props.setLoadingState(true);
                            
                            let response = await api.post("self/changePassword", {currentPassword: this.state.currentPassword, newPassword: this.state.newPassword})
                            this.setState({ success: response, attempted: true })
                            this.props.setLoadingState(false);
                        }}
                        style={{ width: '40%', marginLeft: '10%'}}
                    >{this.state.phrases.btnSubmit}</button>}

                </div>

             </div>);

    }

};