// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import Camera from 'react-html5-camera-photo';
import Scrollbars from 'react-custom-scrollbars';
import 'react-html5-camera-photo/build/css/index.css';
import '../styles/docCapture.css';
import PopupPage from "../components/EOBPopUp.js";
import {FACING_MODES} from "react-html5-camera-photo";

import base64ToBlob from '../utilities/base64toBlob';

import {getPhrasesByPageAndLanguage} from '../constants/languagePhrases';

import { pdfjs, Document, Page } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const langKey = 'documentCapture';

export default class DocCapture extends React.Component {

    state = {
        eobPopup: false,
        showingEOB: false,
        EOBPage: 1,
        cameraError: false,
        page: "Main",
        
        chosenPath: '',
        uploadingEob: false,

        phrases: {},
    }

    uploadedEob = null;

    componentDidMount = async () => {
 
        // File uploader for receipt
        this.eobUpload = document.createElement('input');
        this.eobUpload.setAttribute('type', 'file');
    
        if (!this.props.user){
            if (await this.props.tryLogin()) {
                this.props.history.push("/login")
                return null
            }
        }

        if (this.props.user.flagged)
        {
            this.props.history.push("/")
            return true
        }
        
        if (!this.props.newClaimData.plan)
            this.props.history.push("/new-claim")

        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});

        window.scrollTo(0, 0);
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language)
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    onPhotoCapture = async (dataUri) => {
        // Store photo blob and url
        
        const imgBlob = base64ToBlob(dataUri);
        const imgUrl = window.URL.createObjectURL(imgBlob);

        await this.props.onPhotoCapture({src: imgUrl, blob: imgBlob});
    }

    closePopup = () => {
        this.setState({eobPopup: false})
    }

    handleCameraError = () => {
        this.props.setLoadingState(false);
        this.setState({cameraError : true})
    }

    getErrorPage = () => {
        return (
            <div>
                <div className="nouser-container" style={{ marginTop: 0, paddingTop: 0}}>
                    <h1>{this.state.phrases.msgCameraNotFound}</h1>
                    <div>                   

                        <button
                            style={{
                                position: "fixed",
                                width: "200px",
                                bottom: "2%",
                                left: "calc(50% - 200px/2)",

                            }}
                            onClick={() => this.setState({ page: 'Main' })}
                        >{this.state.phrases.btnBack}
                        </button>

                        <p>{this.state.phrases.msgClickOnPhone}</p>
                        <p>{this.state.phrases.msgAfterSteps}</p>

                        <h1 style={{ paddingTop: "1vh" }} onClick={() => this.setState({ showIOS: !this.state.showIOS })}>{(this.state.showIOS ? '▼ ' : '► ') + " Apple/iOS"}</h1>

                        {this.state.showIOS && <div>
                            <p>{this.state.phrases.msgInIos}</p>

                            <p>{this.state.phrases.msgOpenSettings}</p>

                            <img
                                src="howToAllowCamera/ios/ios_1_settings.svg"
                                alt="img1"
                                width="100%"
                            />

                            <br />
                            
                            <p>{this.state.phrases.msgClickSafari}</p>

                            <br />
                            
                            <img
                                src="howToAllowCamera/ios/ios_2_safari.svg"
                                alt="img2"
                                width="100%"
                            />

                            <br />
                            
                            <p>{this.state.phrases.msgClickCamera}</p>

                            <br />

                            <img
                                src="howToAllowCamera/ios/ios_3_camera.svg"
                                alt="img3"
                                width="100%"
                            />

                            <br />
                            
                            <p>{this.state.phrases.msgSelectAsk}</p>

                            <br />

                            <img
                                src="howToAllowCamera/ios/ios_4_ask.svg"
                                alt="img4"
                                width="100%"
                            />

                            <br />

                            <div>{this.state.phrases.msgCloseApp}</div>

                        </div>}

                        <h1 onClick={() => this.setState({ showAndroid: !this.state.showAndroid })}>{(this.state.showAndroid ? '▼ ' : '► ') + " Android"}</h1>
                        
                        {this.state.showAndroid && <div>

                            <br />

                            <div>{this.state.phrases.msgOpenSettings}</div>

                            <br />

                            <img
                                src="howToAllowCamera/android/android_1_settings.svg"
                                alt="img5"
                                width="100%"
                            />

                            <br />

                            <div>{this.state.phrases.msgFindOptions}</div>

                            <br />

                            <img
                                src="howToAllowCamera/android/android_3_app.svg"
                                alt="img6"
                                width="100%"
                            />

                            <br />

                            <div>{this.state.phrases.msgClickPermissions}</div>

                            <br />

                            <img
                                src="howToAllowCamera/android/android_4_permissions.svg"
                                alt="img7"
                                width="100%"
                                style={{ paddingBottom: "50px" }}
                            />

                            <br />

                            <div>{this.state.phrases.msgClickCamera}</div>

                            <br />

                            <img
                                src="howToAllowCamera/android/android_5_camera.svg"
                                alt="img7"
                                width="100%"
                                style={{ paddingBottom: "50px" }}
                            />

                            <br />

                            <div>{this.state.phrases.msgClickAllow}</div>

                            <br />

                            <img
                                src="howToAllowCamera/android/android_6_allow.svg"
                                alt="img7"
                                width="100%"
                                style={{ paddingBottom: "50px" }}
                            />

                            <br />

                            <div>{this.state.phrases.msgCloseApp}</div>

                        </div>}
                    </div>
                </div>
            </div>
        )
    }

    uploadEob = async (e) => {

        this.eobUpload.click();
        this.eobUpload.onchange = async e => {
            // window.alert('here ' + e.target.files[0]);
            try {
                const fromPath = e.path && e.path.length > 0;
                let file = e.path && e.path.length > 0 ? e.path[0].files[0] : e.target.files[0];
                // window.alert('file upload' + file)
                let reader = new FileReader();
                reader.onload = e => {
                    const blob = new Blob([new Uint8Array(e.target.result)], {type: file.type });
                    const src = window.URL.createObjectURL(blob);
                    this.props.onPhotoCapture({src, blob, type: file.type.split('/').length > 1 ? file.type.split('/')[1] : 'png'});
                };
                reader.readAsArrayBuffer(file);
            } catch (err) {
                console.log('blob err', err)
            }
        }
        
    }

    showDocument = () => {

        return <div className="container" style={{paddingTop: "calc(20vh + 32px)"}}>

                <Document
                    file={this.state.showingEOB.blob}
                    alt="preview"
                    // renderMode="canvas"
                ><Page
                    pageNumber={this.state.EOBPage}
                    width={ window.innerWidth - 40 }
                    onLoadSuccess={() => {this.props.setLoadingState(false)}}
                    renderTextLayer={false}
                /></Document>

                <img
                    onClick={() => {this.setState({showingEOB: false, EOBPage: 1})}}
                    src={"/arrow.png"}
                    alt="arrow"
                    style={{ height: '32px', position: "absolute", left: "16px", top: "20vh", transform:"rotate(-90deg)"}}
                />

                <>
                    {this.state.EOBPage > 1 && <button
                        onClick={() => {
                            this.setState({EOBPage: this.state.EOBPage - 1})
                            this.props.setLoadingState(true)
                        }}
                        style={{ width: '40%', marginLeft: '5%', marginRight: "5%"}}
                    >{this.state.phrases.msgLast}
                    </button> }

                    {(this.state.EOBPage < this.state.showingEOB.pages) && <button
                        onClick={() => {
                            this.setState({EOBPage: this.state.EOBPage + 1})
                            this.props.setLoadingState(true)
                        }}
                        style={{ width: '40%', marginLeft: this.state.EOBPage > 1 ? '5%' : "55%"}}
                    >{this.state.phrases.msgNext}
                    </button>}
                </>

            </div>

    }

    setDocument = blob => {
        this.setState({showingEOB: blob})
    }

    render = () => {

        const pages = this.props.newClaimData.images ? this.props.newClaimData.images.length : 0;

        if (this.state.showingEOB) {
            return this.showDocument()
        }

        else if (this.state.page === "Main") { return (
            <div className="container">
                <h1>{this.state.phrases.msgGetPaperwork1}<br></br>{this.state.phrases.msgGetPaperwork2}</h1>

                <p><strong>{this.state.phrases.msgWhatIsEob}</strong></p>
                <p>{this.state.phrases.msgExplanationOfBenefits}</p>                

                <div className="hint" onClick={() => {this.setState({eobPopup: !this.state.eobPopup})}}>
                    {this.state.phrases.btnFindEob}
                </div>

                {this.state.eobPopup ? <PopupPage close={this.closePopup} setLoadingState={this.props.setLoadingState} setDocument={this.setDocument} language={this.props.language}></PopupPage> : null}

                <p style={{marginBottom:"36px", marginTop:"2px"}}>{this.state.phrases.msgClickCameraBtn}</p>
                {/* <p style={{marginTop:"2px", marginBottom:"24px"}}>Simply press the camera button below!</p> */}

                <div className="take-photos-container">
                    <img
                        className = "takePhotosButton" 
                        src="/camera.svg"
                        alt="cameraIcon"
                        onClick={() => {
                            if (!this.state.cameraError)
                                this.props.setLoadingState(true);
                            this.setState({page:"Capture", chosenPath: 'Capture'})
                        }}
                    />
                </div>
                <div className='uploadEobContainer'>
                    {/* <button
                        className="uploadEob"
                        onClick={async () => this.setState({page: 'Upload', chosenPath: 'Upload'})}
                    >Upload Files
                    </button> */}
                    <img
                        className='upload-btn'
                        title='Upload File'
                        src="/upload.svg"
                        alt="upload icon"
                        onClick={async () => this.setState({page: 'Upload', chosenPath: 'Upload'})}
                    />
                </div>
            
            </div>
        )}

        else if (this.state.page === "Capture") { return (

            <div className="container">

                {!this.state.cameraError && <p style={{margin:"2px", textAlign:"center"}}>{this.state.msgTakePhoto}</p>}
            
                <div className='cameraWrapper' style={{marginTop:"12px"}}>

                    {this.state.cameraError
                    ? this.getErrorPage()
                    : <Camera
                        // idealResolution={{width:640, height: 1080}}
                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                        isImageMirror = {false}
                        onCameraError ={ this.handleCameraError }
                        onTakePhoto={ (dataUri) => this.onPhotoCapture(dataUri)}
                        onCameraStart={() => this.props.setLoadingState(false)}
                    /> 
                    }
                    
                </div>

                {!this.state.cameraError && <p style={{marginTop:"30px", textAlign:"center"}}>{this.state.phrases.msgFileToReview(pages)[this.props.language]}</p>}

                {!this.state.cameraError && <button className="next-page"
                    onClick={() => this.setState({page: "Main"})}
                    style={{ width: '40%', marginLeft: '5%' }}
                    >{this.state.phrases.btnBack}
                </button>}
                
                { pages ? <button
                    onClick={() => this.setState({page: "Review"})}
                    style={{ width: '40%', marginLeft: '10%' }}
                    >{this.state.phrases.btnReview}
                </button> : ""}

            </div>
        )}
        
        else if (this.state.page === "Upload") { return (

            <div className="container">

                <p style={{margin:"2px", textAlign:"center"}}>{this.state.phrases.msgUploadEob}</p>
            
                <p style={{marginTop:"30px", textAlign:"center"}}>{pages + this.state.phrases.msgFileToReview(pages)[this.props.language]}</p>

                <div style={{ marginTop: "0px", textAlign: "center"}}>
                    <img
                        className='uploadEob'
                        title='Upload File'
                        src="/upload.svg"
                        alt="upload icon"
                        onClick={async () => this.uploadEob()}
                    />
                </div>

                <button className="next-page"
                    onClick={() => this.setState({page: "Main"})}
                    style={{ width: '40%', marginLeft: '5%' }}
                    >{this.state.phrases.btnBack}
                </button>
                
                { pages ? <button
                    onClick={() => this.setState({page: "Review"})}
                    style={{ width: '40%', marginLeft: '10%' }}
                    >{this.state.phrases.btnReview}
                </button> : ""}

            </div>
        )}

        else if (this.state.page === "Review") { return (


            <div className="container">
            
                <h3 style={{margin:"2px", textAlign:"center"}}>{this.state.phrases.msgReviewDocs}</h3>

                <div className='claimImageContainer'>
                    <Scrollbars >
                        {this.props.newClaimData.images && this.props.newClaimData.images.map( (image, idx) => {
                            return (
                                <div className='claimImg' key={idx}>

                                    {image.type && image.type === 'pdf' ? <Document
                                        file={image.src}
                                        alt="preview"
                                        onClick = {() => {window.open(image.src)}}
                                        renderMode="canvas"
                                    >
                                        <Page pageNumber={1} width={this.props.containerRef.current ? Math.floor(this.props.containerRef.current.offsetWidth * 0.95) : 370}/>
                                    </Document> : <img src={image.src} alt='document' />}                                    
                                   
                                    {/* Cancel Button */}
                                    <div className='claimImgCancel' title="Delete" onClick={() => this.props.deleteClaimImage(idx)}>
                                        X
                                    </div>

                                </div>
                            )

                        })}
                    </Scrollbars>
                </div>

                <button className="next-page"
                    onClick={() => {
                        this.props.setLoadingState(this.state.chosenPath === "Capture" ? true : false);
                        this.setState({page: this.state.chosenPath})
                    }}
                    style={{ width: '40%', marginLeft: '5%' }}
                    >{this.state.phrases.btnBack}
                </button>

                {(this.props.newClaimData.images && this.props.newClaimData.images.length !== 0) && <button
                    onClick={() => {
                        if (this.props.newClaimData.images && this.props.newClaimData.images.length !== 0)
                            this.props.history.push('/finishup-signature')
                    }}
                    style={{ width: '40%', marginLeft: '10%' }}
                    >{this.state.phrases.btnSubmit}
                </button>}

            </div>

        )}
            


        //     <div className="side-by-side-button">
        //         <button className="next-page"
        //             onClick={() => this.props.history.push('#')}
        //             onSubmit={() => this.props.history.push('#')}
        //             style={{ width: '80%', marginLeft: '10%' }}
        //             >Next
        //             </button>

        //             <button className="next-page"
        //             onClick={() => {
        //                 if (this.props.newClaimData.images && this.props.newClaimData.images.length !== 0)
        //                     this.props.history.push('/finishup-signature')
        //             }}
        //             style={(this.props.newClaimData.images && this.props.newClaimData.images.length !== 0)
        //                 ? { width: '80%', marginLeft: '10%' }
        //                 : { width: '80%', marginLeft: '10%', backgroundColor: "#fee", color: "#a33"}}
        //             >{(this.props.newClaimData.images && this.props.newClaimData.images.length !== 0) ? "Next" : "Take a Picture"}
        //         </button>
        //     </div>
        // </div>
    
}

};