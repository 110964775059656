// constructs object by language for the given page
export const getPhrasesByPageAndLanguage = (pageKey, language) => {

    if (language == null || pageKey == null) {
        console.log("getPhrases params cannot be null");
        return {};
    }
    if (allPhrases[pageKey] == null) {
        console.log("error in getPhrases: page", "\"" + pageKey + "\"", "DNE");
        return {};
    }

    let phrases = {};
    for (let key of Object.keys(allPhrases[pageKey])) {
        if (allPhrases[pageKey][key] instanceof Function) {
            console.log("is func")
            phrases[key] = allPhrases[pageKey][key];
            continue;
        }
        phrases[key] = allPhrases[pageKey][key][language];
    }
    return phrases;
}

const accountSettings = {
    msgAccountSettings: {
        english: "Account Settings",
        spanish: "Configuración de la cuenta",
    },
    lblFullName: {
        english: "Full Name",
        spanish: "Nombre completo",        
    },
    lblPhoneNumber: {
        english: "Phone Number",
        spanish: "Número de teléfono",
    },
    lblEmail: {
        english: "Email",
        spanish: "Correo electrónico",
    },
    lblPassword: {
        english: "Password",
        spanish: "Contraseña",
    },
    btnChangePassword: {
        english: "Change Password",
        spanish: "Cambiar Contraseña",
    },
}

const changePassword = {
    msgChangePassword: {
        english: "Change Password",
        spanish: "Cambiar Contraseña",
    },
    lblCurrentPassword: {
        english: "Current Password",
        spanish: "Contraseña actual",
    },
    lblNewPassword: {
        english: "New Password",
        spanish: "Nueva contraseña",
    },
    lblRetypeNewPassword: {
        english: "Re-type New Password",
        spanish: "Repita la nueva contraseña",
    },
    btnBack: {
        english: "Back",
        spanish: "Volver",
    },
    btnSubmit: {
        english: "Submit",
        spanish: "Enviar",
    },

    // messages
    msgIncorrectPassword: {
        english: "Incorrect Password",
        spanish: "Contraseña incorrecta",
    },
    msgPasswordChanged: {
        english: "Password Changed!",
        spanish: "¡Contraseña cambiada!",
    },
    msgPasswordsDontMatch: {
        english: "Passwords don't match",
        spanish: "Las contraseñas no coinciden",
    },
}

const claims = {
    msgClaims: {
        english: "Claims",
        spanish: "Reclamaciones",
    },
    msgPending: {
        english: "pending",
        spanish: "pendiente",
    },
    lblClaimCode: {
        english: "Claim Code",
        spanish: "Código de reclamo",
    },
    lblStatus: {
        english: "Status",
        spanish: "Estatus",
    },
    lblSubmitDate: {
        english: "Submit Date",
        spanish: "Fecha de envío",
    },
    lblDocCount: {
        english: "Document Count",
        spanish: "Conteo de documentos",
    },
}

const documentCapture = {
    msgGetPaperwork1: {
        english: "Let's Get Your",
        spanish: "Revisemos Sus",
    },
    msgGetPaperwork2: {
        english: "Paperwork",
        spanish: "Documentos",
    },
    msgWhatIsEob: {
        english: "What is an EOB?",
        spanish: "¿Qué es una EOB?",
    },
    msgExplanationOfBenefits: {
        english: "An EOB (explanation of benefits) is sent by your insurance company.  It is a statement summarizing your most recent claims, by person, charges, discounts, and how much you are responsible to pay.  We treat this information confidentially as required by Federal Privacy laws.",
        spanish: "Una EOB (Explicación De Beneficios) es enviada por tu compañía de seguros. Es una declaración que resume sus solicitudes más recientes, por persona, cargos, descuentos, y cuánto es lo que tienes que pagar. Tratamos esta información confidencialmente como lo requieren las leyes federales de privacidad.",
    },
    btnFindEob: {
        english: "How to find my EOB?",
        spanish: "¿Cómo encontrar mi EOB?",
    },
    msgClickCameraBtn: {
        english: "Click on the camera button below to take a picture, or the upload button to upload files from your device!",
        spanish: "¡Haga clic en el botón de la cámara de abajo para tomar una foto, o en el botón de carga para subir los archivos de su dispositivo!",
    },
    
    // upload page
    msgUploadEob: {
        english: "Upload your EOB files in PDF or image format. When you have all the documents, click on \"Review\".",
        spanish: "Sube tus archivos EOB en formato PDF o de imagen. Cuando tengas todos los documentos, haz clic en \"Revisar\".",
    },
    btnBack: {
        english: "Back",
        spanish: "Volver",
    },
    btnReview: {
        english: "Review",
        spanish: "Revisar",
    },
    msgFileToReview: (numPages) => {
        return {
            english: " file" + (numPages === 1 ? "" : "s") + " to review.",
            spanish: " archivo" + (numPages === 1 ? "" : "s") + " para revisar.",
        }
    },

    // review page
    msgReviewDocs: {
        english: "Review Your Documents Below",
        spanish: "Revisa tus documentos a continuación",
    },
    btnSubmit: {
        english: "Submit",
        spanish: "Enviar",
    },
    msgTakePhoto: {
        english: "Take a photo of each page of your EOB. When you have all the pages, click on \"Review\".",
        spanish: "Toma una foto de cada página de tu EOB. Cuando tengas todas las páginas, haz clic en \"Revisar\"",
    },

    // camera not found
    msgCameraNotFound: {
        english: "Camera not found or permission not given.",
        spanish: "Cámara no encontrada o permiso no otorgado.",
    },
    msgClickOnPhone: {
        english: "Click on your phone operating system and follow the instructions in order to give FBC access to your camera.",
        spanish: "Haga clic en el sistema operativo de su teléfono y siga las instrucciones para que FBC acceda a su cámara.",
    },
    msgAfterSteps: {
        english: "After you follow the steps, close the app and open it again.",
        spanish: "Después de seguir los pasos, cierre la aplicación y ábrala nuevamente.",
    },
    msgInIos: {
        english: "In iOS, make sure you added the app to your home screen from Safari, as it may not work from other browsers. If you did not, please remove the app and add it again from the Safari web browser.",
        spanish: "En iOS, asegúrese de haber agregado la aplicación a su pantalla de inicio desde Safari, ya que es posible que no funcione en otros navegadores. Si no lo hizo, elimine la aplicación y vuelva a agregarla desde el navegador web Safari.",
    },
    msgOpenSettings: {
        english: "Open the Settings app.",
        spanish: "Abra la aplicación Configuración.",
    },
    msgClickSafari: {
        english: "Find and click on the \"Safari\" option.",
        spanish: "Busque y haga clic en la opción \"Safari\".",
    },
    msgClickCamera: {
        english: "Click \"Camera\".",
        spanish: "Haga clic en \"Cámara\".",
    },
    msgSelectAsk: {
        english: "Select either \"Ask\" or \"Allow\" to give FBC camera permissions.",
        spanish: "Seleccione \"Preguntar\" o \"Permitir\" para otorgar permisos a la cámara FBC.",
    },
    msgCloseApp: {
        english: "Close the app and open it again for the changes to take effect.",
        spanish: "Cierre la aplicación y ábrala nuevamente para que los cambios surtan efecto.",
    },
    
    msgFindOptions: {
        english: "Find the options for your web browser.",
        spanish: "Busque las opciones para su navegador web.",
    },
    msgClickPermissions: {
        english: "Click \"Permissions\".",
        spanish: "Haga clic en \"Permisos\".",
    },
    msgClickAllow: {
        english: "Click \"Allow\".",
        spanish: "Haga clic en \"Permitir\".",
    },
    msgClickAllow: {
        english: "Click \"Allow\".",
        spanish: "Haga clic en \"Permitir\".",
    },

    msgLast: {
        english: "Last Page",
        spanish: "Última Página",
    },
    msgNext: {
        english: "Next Page",
        spanish: "Siguiente Página",
    }
}

const eobPopUp = {
    msgFindEob: {
        english: "How to find my EOB?",
        spanish: "¿Cómo encontrar mi EOB?",
    },
    msgYourEob: {
        english: "Your Explanation of Benefits (EOB) document can be retrieved from your insurance carrier. Please contact your insurance provider directly to get your EOB document. Select a provider below to see examples of what EOBs look like:",
        spanish: "Tu documento de Explicación de Beneficios (EOB) puede ser obtenido de su compañía de seguros. Contacta directamente atu proveedor de seguros para obtener tu documento EOB. Selecciona un proveedor de los que aquí se muestran para ver ejemplos de cómo se ven las EOBs:",
    },
    lblInsuranceProviders: {
        english: "Insurance Providers",
        spanish: "Proveedores de seguros",
    },
}

const finishUp = {
    msgLetsFinish: {
        english: "Let's Finish Up",
        spanish: "Terminemos",
    },
    msgWhoClaimFor: {
        english: "Who is this claim for?",
        spanish: "¿Para quién es esta reclamación?",
    },
    msgTypeName: {
        english: "Type Name",
        spanish: "Escriba su nombre",
    },
    msgBySigningBelow: {
        english: "By signing below, you are providing FBC Services, Inc. authorization to view, store, and process your insurance documents per HIPAA regulations.",
        spanish: "Al firmar a continuación, usted está proporcionando a FBC Services, Inc. la autorización para ver, almacenar y procesar sus documentos de seguro según las regulaciones de la HIPAA.",
    },
    msgMySignature: {
        english: "My Signature",
        spanish: "Mi firma",
    },
    msgDrawWithFinger: {
        english: "Draw with your finger",
        spanish: "Delinea con tu dedo",
    },
    btnClear: {
        english: "Clear",
        spanish: "Borrar",
    },
    btnAddSignature: {
        english: "Add Signature",
        spanish: "Añadir firma",
    },
    errClaimFor: {
        english: "Claim is For?",
        spanish: "¿Para quién es la reclamación?",
    },
    msgUploadingDocs: {
        english: "Uploading Documents...",
        spanish: "Cargando documentos...",
    },
    btnFinish: {
        english: "Finish",
        spanish: "Terminar",
    },
}

const home = {
    // main
    msgClaimsPending: function(pendingCount) {
        return {
            english: "Claim" + (pendingCount > 1 ? "s" : "") + " pending",
            spanish: "Reclamación" + (pendingCount > 1 ? "es" : "") + " pendiente" + (pendingCount > 1 ? "s" : ""),
    }},
    // {this.state.phrases.msgClaimsPending(this.state.pending.length)}
    btnView: {
        english: "View",
        spanish: "Ver",
    },
    msgHi: {
        english: "Hi",
        spanish: "Hola",
    },
    msgWelcome: {
        english: "Welcome to the FBC Insurance Claims App!",
        spanish: "¡Bienvenido(a) a la aplicación de Reclamos de Seguros de la FBC!",
    },
    msgUsingThisApp: {
        english: "Using this app, you can create and manage your FBC insurance claims.",
        spanish: "Usando esta aplicación, puedes crear y administrar sus reclamos de seguro FBC.",
    },
    msgExplore: {
        english: "Explore the app using the navigation icon in the top left of your screen.",
        spanish: "Explora la aplicación usando el icono de navegación en la parte superior izquierda de tu pantalla.",
    },
    msgStart1: {
        english: "To start a new claim, click on the ",
        spanish: "Para iniciar una nueva reclamación, haz clic en el botón ",
    },
    msgStart2: {
        english: "New Claim",
        spanish: "Nueva Reclamación",
    },
    msgStart3: {
        english: " button below",
        spanish: " de abajo",
    },
    btnNewClaim: {
        english: "New Claim",
        spanish: "Nueva reclamación ",
    },

    // flagged
    msgUhOh: {
        english: "Uh Oh!",
        spanish: "¡Uh Oh!",
    },
    msgMissing: {
        english: "Something is missing...",
        spanish: "Falta algo...",
    },
    msgDontHaveInfo: {
        english: "We don't have all the information we need from you to process your claim.",
        spanish: "No tenemos toda la información que necesitamos para procesar su reclamación.",
    },
    msgPleaseCall: {
        english: "Please call the phone number below to reach one of our claims specialists. They will help you get everything sorted out and moving forward in no time!",
        spanish: "Por favor, llame al número de teléfono que aparece a continuación para ponerse en contacto con uno de nuestros especialistas. ¡Ellos le ayudarán a que todo se solucione en poco tiempo!",
    },
}

const login = {
    // main page
    msgWelcome: {
        english: "Welcome!",
        spanish: "¡Bienvenido(a)!",
    },
    msgLoginToAccount: {
        english: "Log in to your account",
        spanish: "Ingresa a tu cuenta",
    },
    lblPasswd: {
        english: "Password",
        spanish: "Contraseña",
    },
    lblEmail: {
        english: "E-mail",
        spanish: "Correo electrónico",
    },
    btnForgot: {
        english: "Forgot?",
        spanish: "¿La olvidaste?"
    },
    btnLogin: {
        english: "Login",
        spanish: "Inicia sesión",
    },
    btnCreateNow: {
        english: "Create now",
        spanish: "Crear una cuenta ahora",
    },
    btnClickToAddApp1: {
        english: "Click Here to Add the FBC",
        spanish: "¡Haga clic aquí para añadir la aplicación"
    },
    btnClickToAddApp2: {
        english: "App to your home screen!",
        spanish: "FBC a su pantalla de inicio!"
    },
    msgNoAccount: {
        english: "Don't have an account?",
        spanish: "¿No tienes una cuenta?",
    },

    // errors
    msgBadEmail: {
        english: "You have entered an invalid E-mail or Password. Please try again.",
        spanish: "Ha introducido un correo o una contraseña inválida. Por favor, inténtelo de nuevo.",
    },
    msgBadPasswd: {
        english: "You have entered an invalid password. Please try again.",
        spanish: "Ha introducido una contraseña inválida. Por favor, inténtelo de nuevo.",
    },

    // in instructions
    returnToLogin: {
        english: "Return to Login",
        spanish: "Volver al inicio de sesión",
    },
}

const template = {
    btnHome: {
        english: "Home",
        spanish: "Inicio",
    },
    btnNewClaim: {
        english: "New Claim",
        spanish: "Nueva reclamación",
    },
    btnMyClaims: {
        english: "My Claims",
        spanish: "Mis reclamaciones",
    },
    btnMyAccount: {
        english: "My Account",
        spanish: "Mi cuenta",
    },
    btnSupport: {
        english: "Support",
        spanish: "Soporte",
    },
    btnLogOut: {
        english: "Log Out",
        spanish: "Cerrar sesión",
    },
}

const newClaim = {
    // add plan
    msgAddPlan: {
        english: "Add A Plan!",
        spanish: "¡Añade un plan!",
    },
    msgConnect: function(planExistsAlready) {
        return {
            english: "Connect " + (planExistsAlready ? "another" : "your") + " insurance plan",
            spanish: "Conecta " + (planExistsAlready ? "otro" : "tu") + " plan de seguro",
        }
    },
    msgInstructions1: {
        english: "Each insurance plan has a unique 6 letter ",
        spanish: "Cada plan de seguro tiene asociado un ",
    },
    msgInstructions2: {
        english: "Plan Code",
        spanish: "único código",
    },
    msgInstructions3: {
        english: " associated with it. Please enter it below.",
        spanish: " de 6 letras. Por favor, introdúcelo a continuación.",
    },
    btnFindPlan: {
        english: "How to find my Plan Code",
        spanish: "¿Cómo encontrar mi Código de Plan?",
    },
    lblPlanCode: {
        english: "Plan Code",
        spanish: "Código de Plan",
    },
    btnBack: {
        english: "Back",
        spanish: "Volver",
    },
    btnValidate: {
        english: "Validate Code",
        spanish: "Validar código",
    },
    
    // choose plan
    msgGetStarted: {
        english: "Let's Get Started!",
        spanish: "¡Empecemos!",
    },
    msgSelectPlan: {
        english: "Select an insurance plan",
        spanish: "Selecciona un plan de seguro",
    },
    dropClickToSelect: {
        english: "Click to select a plan",
        spanish: "Haz clic para seleccionar un plan",
    },
    btnAddPlan: {
        english: "Add Plan",
        spanish: "Añadir plan",
    },
    btnRemovePlan: {
        english: "Remove Plan",
        spanish: "Eliminar plan",
    },
    btnContinue: {
        english: "Continue",
        spanish: "Continuar",
    },

    // remove plan
    btnDelete: {
        english: "Delete",
        spanish: "Borrar",
    },
    confirmDelete: {
        english: "Are you sure you want to delete the plan \"",
        spanish: "¿Estás seguro de que quieres borrar el plan \""
    },
    
    // words
    wordCode: {
        english: "code",
        spanish: "código",
    },

    // error messages
    errEnterCode: {
        english: "Please enter a 6 letter Code",
        spanish: "Por favor, introduce un código de 6 letras",
    },
    errInvalidCode1: {
        english: "Invalid code, please re-enter or contact",
        spanish: "Código inválido, por favor vuelve a introducir o contacta",
    },
    errInvalidCode2: {
        english: "your HR department for the correct code.",
        spanish: "con tu departamento de recursos humanos para obtener el código correcto.",
    },
    msgValidPlanCode: {
        english: "Valid plan code!",
        spanish: "¡Código de plan válido!",
    },
    msgPlanAdded: {
        english: "The plan has been added to your account",
        spanish: "El plan ha sido añadido a su cuenta",
    }
}

const planCodePopUp = {
    msgFindCode: {
        english: "How to find my Plan Code?",
        spanish: "¿Cómo encontrar mi código de plan?"
    },
    msgInstructions: {
        english: "You can retrieve your plan code from your HR Department, please contact your HR department directly for the code. Each insurance plan will contain a unique 6 letter code.",
        spanish: "Puedes recuperar tu código de plan en tu departamento de Recursos Humanos, por favor contáctalos directamente para obtener el código. Cada plan de seguro contendrá un código único de 6 letras.",
    },
}

const app = {
    msgAppOnPhone1: {
        english: "On a desktop?",
        spanish: "¿En una computadora de escritorio?",
    },
    msgAppOnPhone2: {
        english: "The FBC App looks better on your phone!",
        spanish: "¡La aplicación de la FBC se ve mejor en tu teléfono!",
    },
    msgAppOnPhone3: {
        english: "Click here for instructions!",
        spanish: "¡Haz clic aquí para obtener instrucciones!",
    },
    btnBack: {
        english: "Back",
        spanish: "Volver",
    },
}

const addAppInstructions = {
    cmdNavigate: {
        english: "Navigate to myfbcapp.com on your phone. Then click on your phone operating system and follow the instructions in order to add the FBC app to your home screen.",
        spanish: "Dirígete a myfbcapp.com en tu teléfono. Luego haz clic en el sistema operativo de tu teléfono y sigue las instrucciones para agregar la aplicación FBC a tu pantalla de inicio.",
    },

    // ios
    ios_msgUseSafari: {
        english: "Be sure you are using Safari. We will not be able to access your camera from any other browser!",
        spanish: "¡Asegúrate de que estás usando Safari. No podremos acceder a tu cámara desde ningún otro navegador!",
    },
    ios_msgClickSendToBtn: {
        english: "First click on the \"Send To\" Button on the bottom of the browser.",
        spanish: "Primero haz clic en el botón \"Enviar a\" en la parte inferior del navegador.",
    },
    ios_msgHomeScreenBtn: {
        english: "Then select the \"Add to Home Screen\" Button from the drop down.",
        spanish: "Luego selecciona el botón \"Agregar a la pantalla de inicio\" del menú de opciones.",
    },
    ios_msgPressAdd: {
        english: "Press Add",
        spanish: "Presiona agregar",
    },
    ios_msgNowAccessible: {
        english: "The FBC App should now be accessible from your home screen!",
        spanish: "¡La aplicación de la FBC debería ser accesible ahora desde tu pantalla de inicio!",
    },

    // android
    android_msgSomeBrowsers: {
        english: "Some browsers may not support adding websites to your home screen. The instructions below are most applicable if you are using Google Chrome.",
        spanish: "Algunos navegadores pueden no aceptar la inclusión de sitios web a tu pantalla de inicio. Las siguientes instrucciones son más aplicables si estás usando Google Chrome",
    },
    android_msgSelectSettings: {
        english: "Select the settings option in the browser.",
        spanish: "Selecciona la opción de configuración del navegador.",
    },
    android_msgHomeScreenBtn: {
        english: "Select \"Add to Home screen\" from the drop down.",
        spanish: "Selecciona \"Agregar a la pantalla de inicio\" del menú desplegable.",
    },
    android_msgFollow: {
        english: "Follow the on screen prompts.",
        spanish: "Sigue las indicaciones de la pantalla.",
    },
    android_msgNowAccessible: {
        english: "The FBC App should now be accessible from your home screen!",
        spanish: "¡La aplicación de la FBC debería ser accesible ahora desde tu pantalla de inicio!",
    },
}

const signup = {
    msgPleaseCompleteForm1: {
        english: "Please Complete the",
        spanish: "Por favor, rellena el",
    },
    msgPleaseCompleteForm2: {
        english: "Form Below",
        spanish: "siguiente formulario",
    },
    lblFullName: {
        english: "Full Name",
        spanish: "Nombre completo",        
    },
    lblPhoneNumber: {
        english: "Phone Number",
        spanish: "Número de teléfono",
    },
    lblEmail: {
        english: "Email",
        spanish: "Correo electrónico",
    },
    lblConfirmEmail: {
        english: "Confirm Email",
        spanish: "Confirmar correo electrónico",
    },
    lblPassword: {
        english: "Password",
        spanish: "Contraseña",
    },
    btnConfirmPassword: {
        english: "Confirm Password",
        spanish: "Confirmar contraseña",
    },
    btnBack: {
        english: "Back",
        spanish: "Volver",
    },
    btnCreate: {
        english: "Create",
        spanish: "Crear",
    },
    msgCreating: {
        english: "Creating...",
        spanish: "Creando...",
    },

    // err
    errEntername: {
        english: "Please enter a name with at least four characters.",
        spanish: "Por favor, introduce un nombre con al menos cuatro caracteres.",
    },
    errPhoneNumber: {
        english: "Please include your full 10 digit phone number.",
        spanish: "Por favor, incluye tu número de teléfono completo de 10 dígitos.",
    },
    errEmailsDontMatch: {
        english: "Emails don't match",
        spanish: "Los correos electrónicos no coinciden",
    },
    errEmailNotValid: {
        english: "Not a valid email",
        spanish: "No es un correo electrónico válido",
    },
    errPasswordsDontMatch: {
        english: "Passwords don't match",
        spanish: "Las contraseñas no coinciden",
    },
    errPassword8Chars: {
        english: "Please choose a password with at least 8 characters",
        spanish: "Por favor, elije una contraseña con al menos 8 caracteres",
    },
    errEmailInUse: {
        english: "The e-mail you entered is already in use. To retrieve a forgotten password, please go back to the login page and click \"forgot\".",
        spanish: "El correo electrónico que ha introducido ya está en uso. Para recuperar una contraseña olvidada, vuelva a la página de inicio de sesión y haga clic en \"la olvidé\".",
    },
    errEmailExists: {
        english: "The e-mail address you entered does not exist. Please try again.",
        spanish: "La dirección de correo electrónico que ha introducido no existe. Por favor, inténtelo de nuevo.",
    },
}

const claimComplete = {
    msgThatsIt: {
        english: "That's it, Great Job!",
        spanish: "¡Eso es, Gran Trabajo!",
    },
    msgWhatsNext: {
        english: "What's Next?",
        spanish: "¿Qué sigue ahora?",
    },
    msgWeReceivedPaperwork: {
        english: "We received your paperwork. A member of our team will review your documents and process your claim. Then, we will send you an email confirming that FBC has processed your claim. You can keep track of the claim status here!",
        spanish: "Recibimos tu solicitud. Un miembro de nuestro equipo revisará tus documentos y procesará tu reclamación. Luego, te enviaremos un correo electrónico confirmando que FBC ha procesado tu reclamo. ¡Puedes seguir el estado de la reclamación aquí!",
    },
    msgHereForYou: {
        english: "We are here for you. Please call us if you need further assistance or have any questions.",
        spanish: "Estamos aquí para ti. Por favor, llámanos si necesitas más ayuda o tienes alguna pregunta.",
    },
}

const support = {
    msgNeedHelp: {
        english: "Need some help?",
        spanish: "¿Necesitas ayuda?",
    },
    msgWeAreHere: {
        english: "We are here for you!",
        spanish: "¡Estamos aquí para ti!",
    },
    msgHaveQuestions: {
        english: "If you have any questions or concerns or need further assistance, please call us. Our office hours are Monday through Friday 8:30 AM to 5:00 PM MST.",
        spanish: "Si tienes alguna pregunta o preocupación o necesitas más ayuda, por favor llámanos. Nuestro horario de oficina es de lunes a viernes de 8:30 AM a 5:00 PM MST.",
    },
    msgCallUs: {
        english: "Give us a call, we are happy to help!",
        spanish: "¡Llámanos, estaremos encantados de ayudarte!",
    },
}

const welcome = {
    msgWelcome1: {
        english: "Welcome",
        spanish: "Bienvenido",
    },
    msgWelcome2: {
        english: "to the FBC Claims App",
        spanish: "a la aplicación de reclamaciones de la FBC",
    },
    msgUsingThisApp: {
        english: "Using this app, you can create and manage your FBC insurance claims to collect your reimbursements. You will also be able to keep track of your active and completed claims.",
        spanish: "Usando esta aplicación, puedes crear y administrar tus reclamos de seguro FBC para cobrar tus reembolsos. También podrás hacer un seguimiento de tus reclamaciones activas y completadas.",
    },
    msgClickGetStartedBtn1: {
        english: "Click on the ",
        spanish: "¡Haz clic en el botón ",
    },
    msgClickGetStartedBtn2: {
        english: "Get Started",
        spanish: "Comenzar",
    },
    msgClickGetStartedBtn3: {
        english: " button below to create an account!",
        spanish: " abajo para crear una cuenta!",
    },    
    btnGetStarted: {
        english: "Get Started",
        spanish: "Empecemos",
    },
}

const forgot = {
    msgForgot1: {
        english: "Forgot your",
        spanish: "¿Olvidaste la",
    },
    msgForgot2: {
        english: "Password?",
        spanish: "Constrasena?",
    },
    msgDontWorry: {
        english: "Don't Worry. We'll reset it and send a new password to your email address.",
        spanish: "No se preocupe. La restableceremos y le enviaremos una nueva contraseña a su dirección de correo electrónico.",        
    },
    btnBack: {
        english: "Back",
        spanish: "Volver",
    },
    msgPasswordReset1: {
        english: "Password successfully reset!",
        spanish: "¡Contraseña restablecida con éxito!",
    },
    msgPasswordReset2: {
        english: "Check your email for your new password!",
        spanish: "¡Revise su correo electrónico para ver su nueva contraseña!",
    },
    msgEmailNotFound: {
        english: "Email address not attached to an account. Try again.",
        spanish: "Dirección de correo electrónico no asociada a una cuenta. Inténtelo de nuevo.",
    },
    btnSubmit: {
        english: "Submit",
        spanish: "Enviar",
    },
}


const clientPlan = {
    msgPlanExplanation: {
        english: "Plan Explanation",
        spanish: "Explicación del Plan",
    },
    btnBack: {
        english: "Back",
        spanish: "Volver",
    },
    btnNext: {
        english: "Next",
        spanish: "Siguiente",
    },
}



const allPhrases = {
    app,
    login,
    addAppInstructions,
    forgot,
    welcome,
    home,
    claims,
    template,
    newClaim,
    planCodePopUp,
    clientPlan,
    documentCapture,
    finishUp,
    claimComplete,
    accountSettings,
    changePassword,
    signup,
    support,
    eobPopUp,
}

export default allPhrases;

export const miscPhrases = {
    pending: {
        english: "pending",
        spanish: "pendiente",
    },
    flagged: {
        english: "flagged",
        spanish: "marcado",
    },
    complete: {
        english: "complete",
        spanish: "completado",
    }
}