// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import api from "../api";

import {getPhrasesByPageAndLanguage} from '../constants/languagePhrases';
const langKey = 'signup';

export default class SignUp extends React.Component {

    state = {
        fullName: '',
        phone: '',
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: '',


        attempted: false,
        success: false,
        passwordsMatch: true,
        strongPassword: true,
        fullNameExists: true,
        emailValid: false,
        phoneValid: false,
        creating: false,

        badFullNameMessage: '',
        badEmailMessage: '',
        badPhoneMessage: '',

        phrases: {},
    }
    

    componentDidMount = async () => {
        // Logout user

        // this.props.onLogout();
        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
        window.scrollTo(0, 0)
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language)
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    createAccount = async () => {

        this.setState({ creating: true }, async () => {

            if (this.state.fullName.length < 4) {
                this.setState({ fullNameExists: false, creating: false, badFullNameMessage: this.state.phrases.errEntername })
                return
            }

            if (!this.state.phoneValid) {
                this.setState({ phoneValid: false, creating: false, badPhoneMessage: this.state.phrases.errPhoneNumber })
                return
            }

            // Passwords don't match. Return without doing anything.
            if (this.state.password !== this.state.confirmPassword) {
                this.setState({passwordsMatch: false, creating: false})
                return
            }

            // Emails don't match. Return without doing anything.
            if (this.state.email !== this.state.confirmEmail) {
                this.setState({badEmailMessage: this.state.phrases.errEmailsDontMatch, creating: false})
                return
            }

            // Emails doesn't look like an email.
            let atLocation = this.state.email.indexOf("@");
            if (atLocation < 1 || this.state.email.slice(atLocation, this.state.email.length).indexOf(".") < 1) {
                this.setState({badEmailMessage: this.state.phrases.errEmailNotValid, creating: false});
                return;
            }

            // Password isn't strong enough
            if (this.state.password.length < 7) {
                this.setState({strongPassword: false, creating: false})
                return
            }

            this.props.setLoadingState(true);

            // Create the user in the db
            const createResult = await api.post("self/create", { object : {
                name: this.state.fullName,
                phone: this.state.phone,
                email: this.state.email,
                password: this.state.password,
            }});

            if (createResult.error) {
                
                let errorMsg;
                
                if (createResult.error === 'email already exists') 
                    errorMsg = this.state.phrases.errEmailInUse;
                else if (createResult.error === 'invalid email address') 
                    errorMsg = this.state.phrases.errEmailExists;
                
                this.setState({ badEmailMessage: errorMsg, creating: false});

            } else if (createResult.user) {
                
                // Log the user in
                await api.post("self/login", { email: this.state.email, password: this.state.password });

                // Requested to no longer create a verification
                // await this.props.createVerification();
                // this.props.history.push('/verify-email');
                this.props.history.push("/")

            } else {
                console.log('createResult unknown result')
            }

            this.props.setLoadingState(false);

        })        
    }

    isPhoneNumberValid = phone => {
        return phone.split('').filter(d => !isNaN(d)).length >= 10;
    }

    render = () => {
        return (<div className="signup-container">
            
            <h1>{this.state.phrases.msgPleaseCompleteForm1}<div>{this.state.phrases.msgPleaseCompleteForm2}</div></h1>

            <label>{this.state.phrases.lblFullName}</label>
            <input 
                type="text"
                value={this.state.fullName}
                // style={{"borderBottom" : this.state.fullNameExists ? "4px solid rgb(230,230,230)" : "4px solid rgb(230,170,170)"}}
                onChange={(e) => this.setState({ fullName: e.target.value, badFullNameMessage: '' })}
                onKeyPress={e => {
                    if (e.key === 'Enter')
                        this.refs.createButton.click();
                }}
            />

            {this.state.badFullNameMessage.length > 0 ? <div className="invalid-text">{this.state.badFullNameMessage}</div> : null}
            
            <label>{this.state.phrases.lblPhoneNumber}</label>
            <input 
                type="text"

                value={this.state.phone}
                onChange={(e) => {
                    this.setState({ phone: e.target.value, phoneValid: this.isPhoneNumberValid(e.target.value), badPhoneMessage: ''})        
                }}
                onKeyPress={e => {
                    if (e.key === 'Enter')
                        this.refs.createButton.click();
                }}
            />

            {this.state.badPhoneMessage.length > 0 ? <div className="invalid-text">{this.state.badPhoneMessage}</div> : null}
            
            <label>{this.state.phrases.lblEmail}</label>
            <input 
                type="text"

                value={this.state.email}
                onChange={(e) => {
                    this.setState({email: e.target.value.toLowerCase(), emailValid: e.target.value.length > 0, badEmailMessage: ''})        
                }}
                onKeyPress={e => {
                    if (e.key === 'Enter')
                        this.refs.createButton.click();
                }}
            />
            <label>{this.state.phrases.lblConfirmEmail}</label>
            <input 
                type="text"

                value={this.state.confirmEmail}
                onChange={(e) => {
                    this.setState({confirmEmail: e.target.value.toLowerCase(), emailValid: e.target.value.length > 0, badEmailMessage: ''})        
                }}
                onKeyPress={e => {
                    if (e.key === 'Enter')
                        this.refs.createButton.click();
                }}
            />

            {this.state.badEmailMessage.length > 0 ? <div className="invalid-text">{this.state.badEmailMessage}</div> : null}

            <label>{this.state.phrases.lblPassword}</label>
            <input 
                type="password"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value, passwordsMatch: true, strongPassword: true })}
                onKeyPress={e => {
                    if (e.key === 'Enter')
                        this.refs.createButton.click();
                }}
            />

            <label>{this.state.phrases.btnConfirmPassword}</label>
            <input 
                type="password"
                value={this.state.confirmPassword}
                onChange={(e) => this.setState({ confirmPassword: e.target.value, passwordsMatch: true, strongPassword: true })}
                onKeyPress={e => {
                    if (e.key === 'Enter')
                        this.refs.createButton.click();
                }}
            />

            {!this.state.passwordsMatch ? <div className="invalid-text">{this.state.phrases.errPasswordsDontMatch}</div> : null}
            {!this.state.strongPassword ? <div className="invalid-text">{this.state.phrases.errPassword8Chars}</div> : null}

            <button
                className="next-page"
                onClick={() => this.props.history.push("/login")}
                style={{ width: '40%', marginLeft: '5%' }}
            >{this.state.phrases.btnBack}</button>

            {this.state.creating ? <button style={{ width: '40%', marginLeft: '10%' }}>{this.state.phrases.msgCreating}</button>
            : <button
                ref='createButton'
                onClick={() => this.createAccount()}
                style={{ width: '40%', marginLeft: '10%' }}
            >{this.state.phrases.btnCreate}</button>}

        </div>)
    }

};