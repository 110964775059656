// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

export default (dataUri) => {


    // Separate bytes and mime
    const uriSplit = dataUri.split(',');

    const mimeType = uriSplit[0].split(':')[1].split(';')[0];
    const byteStr = atob(uriSplit[1]);

    // Init buffer
    const buffer = new ArrayBuffer(byteStr.length);

    // View into buffer
    const view = new Uint8Array(buffer);

    // Write the byteStr to the buffer
    for (let i=0; i<byteStr.length; i++) 
        view[i] = byteStr.charCodeAt(i);


    // Create blob!
    let blob = new Blob([buffer], {type: mimeType});

    return blob;
  

}