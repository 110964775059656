// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import {getPhrasesByPageAndLanguage} from '../constants/languagePhrases';
const langKey = 'planCodePopUp';

export default class PopupPage extends React.Component {

    state = {
        phrases: {},
    }

    componentDidMount = () => {
        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language)
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    render = () => {

        return (
            <div className="popupEOB">
                <img src="close.svg" alt="close icon"
                onClick={() => {this.props.close()}}/>
                <h2>{this.state.phrases.msgFindCode}</h2>
                <p>{this.state.phrases.msgInstructions}</p>
            </div>
        )
    }

}
