// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import {getPhrasesByPageAndLanguage} from '../constants/languagePhrases';

const langKey = 'welcome';

export default class Welcome extends React.Component {

    state = {
        phrases: {},
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language)
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    validateCode = async () => {
        this.setState({
            code: "",
            codeError: true
        });
    }

    render = () => {

        return (<div className="center-container" style={{paddingTop: "30vh"}}>

                <h1>{this.state.phrases.msgWelcome1}<br></br>{this.state.phrases.msgWelcome2}</h1>

                <p>{this.state.phrases.msgUsingThisApp}</p>

                <p>{this.state.phrases.msgClickGetStartedBtn1}<span className="get-started-bold">{this.state.phrases.msgClickGetStartedBtn2}</span>{this.state.phrases.msgClickGetStartedBtn3}</p>

                <button
                    onClick={() => this.props.history.push('/signup')}
                    style={{ width: '80%' }}
                >
                    {this.state.phrases.btnGetStarted}
                </button>
                                

            </div>);

    }

};