// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import api from '../api';

import '../styles/my-claims.css'

import {getPhrasesByPageAndLanguage, miscPhrases} from '../constants/languagePhrases';
const langKey = 'claims';

export default class Claim extends React.Component {

    state = {
        opened: true,
        claimOpened: true,
        claims: [],
        list: [
            {
                title: 'Deductible Amount',
                value: '$1000'
            },
            {
                title: 'Met Reimbursement',
                value: '$2500'
            },
            {
                title: 'Status',
                value: 'Processing'
            }
        ],
        phrases: {},
    };

    componentDidMount = async () => {
        this.props.setLoadingState(true);
        
        if (!this.props.user){
            if (await this.props.tryLogin()) {
                this.props.history.push("/login")
                return null
            }
        }

        if (this.props.user.flagged)
            this.props.history.push("/")
        
        let claims = await api.post("self/claims/getByQuery", { query: { }})
        claims.sort((a, b) => {
            if (a.status > b.status)
                return -1;
            else if (a.status === b.status) {
                if (a.claimNumber < b.claimNumber)
                    return -1;
                return 1;
            }
            return 1;
        })
        
        this.setState({claims})
        this.props.setLoadingState(false);
        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
        window.scrollTo(0, 0);
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language)
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    toggleClaimOpenState = (idx) => {
        let claims = [...this.state.claims];
        claims[idx].opened = !claims[idx].opened
        this.setState({claims})
    }
 
    render = () => {

        if (!this.props.user)
            return null
        
        return (
        <div className="container"> 

            <h1>{this.state.phrases.msgClaims} ({this.state.claims.filter(c => c.status === 'Pending').length}/{this.state.claims.length} {this.state.phrases.msgPending})</h1>
            
            <div>
                <span><h5 style={{display: 'inline-block'}}>{this.state.phrases.lblClaimCode}</h5></span>
                <span><h5 style={{display: 'inline-block', position: 'absolute', left: 'calc(50% - 25px)'}}>{this.state.phrases.lblStatus}</h5></span>
                <span><h5 style={{display: 'inline-block', float: 'right'}}>{this.state.phrases.lblSubmitDate}</h5></span>
            </div>

            {this.state.claims && this.state.claims.length > 0 && this.state.claims.sort((a,b) => { 
                // both complete
                if (a.receiptSentDate && b.receiptSentDate || (!a.receiptSentDate && !b.receiptSentDate)) 
                    return a.createdDate > b.createdDate ? -1 : 1;
                else 
                    return b.receiptSentDate && b.receiptSentDate.length > 0 ? -1 : 1;
                    
            }).map((clm, idx) => {

              

                return (

                    <div className="claim-container" key={'claim_'+idx} onClick={() => this.toggleClaimOpenState(idx)}>

                        <h2 className="claimNumber" style={{cursor: 'pointer', fontSize:'14px', fontWeight: "800",}}>
                            {this.state.claims[idx].opened ? '▼ ' : '► '}#{clm.claimNumber}
                        </h2>

                        {<div className="status claim-status" style={{
                            backgroundColor: clm.status === "Complete" ? "rgb(43, 189, 91)" : "rgb(209,157,91)"
                        }}>{miscPhrases[clm.status.toLowerCase()] ? miscPhrases[clm.status.toLowerCase()][this.props.language] : clm.status}</div>}


                        <h3 className="claim-start-date">{clm.createdDate.substring(0, 10)}</h3>

                        {(this.state.claims[idx].opened) && <div>

                            <div className="claim-detail-container">
                                <span >{this.state.phrases.lblDocCount}: {clm.eob.length}</span>
                            </div>
                            
                            {clm.fields && <div style={{ paddingTop: 20 }}>
                                {Object.entries(clm.fields).map( fieldMap => {

                                    return (
                                        <div className="claim-detail-container" key={fieldMap[0]}>
                                            <span >{fieldMap[0]}</span>
                                            <span>  </span>
                                            <span >{fieldMap[1]}</span>
                                        </div>
                                    )

                                })}
                            </div>}

                            {/* </div> : this.state.claims[idx].opened ? <div style={{ paddingTop: 20 }} className="claim-detail-container"> */}
                                {/* <span>No fields added.</span> */}
                            {/* </div> : '' */}
                            
                        </div>} 
                    </div>
                )
            })}

        </div>)
    
    }

};