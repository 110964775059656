// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import api from "../api";

import {getPhrasesByPageAndLanguage} from '../constants/languagePhrases';

const langKey = 'forgot';

export default class ForgotPage extends React.Component {

    state = {
        email: "",
        error: false,
        success: false,

        phrases: {},
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language)
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    render = () => {

        return (<div className="container">
                    <div className="forogt-pword-container">

                        <h1>{this.state.phrases.msgForgot1}<br></br>{this.state.phrases.msgForgot2}</h1>
                        <p>{this.state.phrases.msgDontWorry}</p>

                        {this.state.success
                            ? <p style = {{color: "rgb(43, 189, 91)", marginBottom: "0px"}}>{this.state.phrases.msgPasswordReset1}<br></br>{this.state.phrases.msgPasswordReset2}</p>
                            : 
                                <div>
                                    <label>E-mail</label>
                                    <input 
                                        type="text"
                                        value={this.state.email}
                                        onChange = {e => this.setState({email: e.target.value.toLowerCase(), error: false, success: false})}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter')
                                                this.refs.submitButton.click();
                                        }}
                                    />
                                </div>
                            }
                        
                        {this.state.error ? <p style = {{color: "rgb(206,51,51)", marginBottom: "0px"}}>{this.state.phrases.msgEmailNotFound}</p> : null}

                    <button
                    className="next-page"
                    onClick={async () => {
                        this.props.history.push("/login")
                    }}
                    style={{ width: '40%', marginLeft: '5%', marginTop: "15%"}}
                    >{this.state.phrases.btnBack}</button>

                    {this.state.email && this.state.email.length > 0 && !this.state.success && <button
                        ref='submitButton'
                        onClick={async () => {
                            this.props.setLoadingState(true);
                            await api.post("email/forgotPassword", {email: this.state.email})
                            .then(() => {
                                this.setState({success: true})
                                this.props.setLoadingState(false);
                            })
                            .catch(() => {
                                this.props.setLoadingState(false);
                                this.setState({error: true})
                            })
                        }}
                        style={{ width: '40%', marginLeft: '10%'}}
                    >{this.state.phrases.btnSubmit}</button>}

                    </div>
                    

             </div>);


    }

};