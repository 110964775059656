// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import api from "../api";
import qs from "qs";

export default class VerifyEmail extends React.Component {

    callbackInterval = null

    componentDidMount = async () => {

        const mountFunc = async () => {

            await this.props.tryLogin();

            // Check to see if a user and key were provided, If so, verify the user.
            let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
            if (queryParams.key && queryParams.userId) {
                if (await api.post("self/verify", {key : queryParams.key, userId : queryParams.userId})) {
                    this.props.history.push("/login")
                    clearInterval(this.callbackInterval)
                    return;
                }
            }

            if (this.props.user && this.props.user.verificationDate)
                this.props.history.push("/");
        }

        await mountFunc()
        this.callbackInterval = setInterval(mountFunc, 1000);

        window.scrollTo(0, 0)
    }

    componentWillUnmount = () => {
        clearInterval(this.callbackInterval)
    }

    validateCode = async () => {
        this.setState({
            code: "",
            codeError: true
        });
    }

    resendEmail = async () => {
        this.props.setLoadingState(true);
        await this.props.createVerification();
    }

    render = () => {

        if (!this.props.user)
            return null

        return (<div className="center-container">

                <h1>Verify your Email</h1>
                <p>Thanks for Signing Up!</p>

                <p>A verification email will be sent to
                    your email shortly. Please follow the
                    instructions in the e-mail to verify
                    your new FBC account!</p>

                <p>This verification email will be valid for 24 hours</p>

                <button
                    style={{ width: '80%', marginLeft: 'auto' }}
                    onClick={() => this.resendEmail()}
                >Resend Email
                </button>
                                

            </div>);

    }

};