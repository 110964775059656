// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
// import PopUp from "reactjs-popup";

import {getPhrasesByPageAndLanguage} from '../constants/languagePhrases';

import { pdfjs, Document, Page } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const langKey = 'eobPopUp';

export default class EobPopup extends React.Component {

    state = {
        phrases: {},
    }

    componentDidMount = () => {
        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language)
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    render = () => {

        return (

            <div className="popupEOB">
                <img src="close.svg" alt="close icon"
                onClick={() => {this.props.close()}}/>
                <h2>{this.state.phrases.msgFindEob}</h2>
                <p>
                {this.state.phrases.msgYourEob}
                </p>

                <h3>{this.state.phrases.lblInsuranceProviders}</h3>
                <ul>
                    {/* <li><a onClick={() => { this.props.setDocument( {blob: "/Aetna EOB.pdf", pages: 4} )}}>Aetna</a></li> */}
                    <li><a onClick={() => { 
                        this.props.setDocument( {blob: "/Banner and Aetna EOB.pdf", pages: 2} );
                        this.props.setLoadingState(true);
                        }}>Banner/Aetna</a></li>
                    <li><a onClick={() => { 
                        this.props.setDocument( {blob: "/Cigna EOB.pdf", pages: 4} );
                        this.props.setLoadingState(true);
                        }}>Cigna</a></li>
                    <li><a onClick={() => { 
                        this.props.setDocument( {blob: "/UHC EOB.pdf", pages: 3} );
                        this.props.setLoadingState(true);
                        }}>United Health Care</a></li>
                </ul>


            </div>
        )
    }

}
