// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import api from "../api";

import {getPhrasesByPageAndLanguage} from '../constants/languagePhrases';

const langKey = 'home';

export default class Home extends React.Component {

    state = {

        pending: [],
        phrases: {},

    }
    componentDidMount = async () => {

        if (!this.props.user){
            if (await this.props.tryLogin()) {
                this.props.history.push("/login")
                return null
            }
        }

        let pendingClaims = await api.post('self/claims/getByQuery', { query: { status: "Pending" } }).catch(err => console.log('err getting claims data', err));
        this.setState({pending: pendingClaims})

        // If the user is flagged, let the operators know the user has viewed the notification.
        if (this.props.user.flagged) {
            await api.get("self/viewFlags", { } )
        }

        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
        window.scrollTo(0, 0)

    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language)
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    render = () => {

        if (!this.props.user)
            return null

        const u = this.props.user;

        return (<>
        
            {/* Pending claims? */}
            {this.state.pending && this.state.pending.length > 0 ? <div className="notice">

                <div className="notice-count">{this.state.pending.length}</div>{this.state.phrases.msgClaimsPending ? this.state.phrases.msgClaimsPending(this.state.pending.length)[this.props.language] : ''}

                <div
                    className="notice-link"
                    onClick={() => this.props.history.push('claims')}
                >{this.state.phrases.btnView}</div>

            </div> : ''}

            {/* Home section */}
            {u.flagged ? 
            // User is flagged
            <div className="container">
                <h1>{this.state.phrases.msgUhOh}</h1>
                <h3>{this.state.phrases.msgMissing}</h3>
                <p>{this.state.phrases.msgDontHaveInfo}</p>
                <p>{this.state.phrases.msgPleaseCall}</p>
                <div className="phone">
                    <a href='tel:18007764243'>1-800-776-4243</a>
                </div>
            </div> :
            
            // Regular home screen
            <div className="container">
                <h1>{this.state.phrases.msgHi} {u.name.split(" ")[0]}!</h1>
                <h3>{this.state.phrases.msgWelcome}</h3>
                <p>{this.state.phrases.msgUsingThisApp}</p>
                <p>{this.state.phrases.msgExplore}</p>
                <p>{this.state.phrases.msgStart1}<strong>{this.state.phrases.msgStart2}</strong>{this.state.phrases.msgStart3}</p>
                <button
                    onClick={() => this.props.history.push('/new-claim')}
                    onSubmit={() => this.props.history.push('/new-claim')}
                    style={{ width: '80%', marginLeft: '10%' }}
                >{this.state.phrases.btnNewClaim}</button>
            </div>}

        </>);
    }

};