// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import SignatureCanvas from 'react-signature-canvas'

import api from "../api";
import '../styles/finishSignature.css';

import {getPhrasesByPageAndLanguage} from '../constants/languagePhrases';
const langKey = 'finishUp';

const signatureRatio = 5/2;
const maxSignatureW = 500;


export default class FinishSignature extends React.Component {

    constructor(props) {
        super(props);
        this.signaturePad = React.createRef();
    }

    state = {
        // Signature Pad Sizing
        signatureDims: [500, 200],

        uploading: false,
        signatureExists: false,

        errorMessage: "",

        claimFor: [''],

        phrases: {},
    }

    componentDidMount = async () => {

        if (!this.props.newClaimData.images || this.props.newClaimData.images.length === 0)
        {
            this.props.history.push("/plan")
            return
        }

        await this.updateSignatureSize();
        if (!this.props.user){
            if (await this.props.tryLogin()) {
                this.props.history.push("/login")
                return null
            }
            
            if (!this.props.user.verificationDate)
            {
                this.props.history.push("/verify-email")
                return null
            }
        }

        if (!this.props.user){
            if (await this.props.tryLogin()) {
                this.props.history.push("/login")
                return null
            }
        }

        if (this.props.user.flagged)
            this.props.history.push("/")

        await this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
        this.setState({errorMessage: this.state.phrases.btnAddSignature})

        window.scrollTo(0, 0);
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language)
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    checkReady = async () => {

        if (!this.state.signatureExists)
        {
            this.setState({ errorMessage: this.state.phrases.btnAddSignature})
            return false
        }

        if (this.state.claimFor.filter(x => x.length > 0).length === 0 || this.state.claimFor.filter(x => x.length > 0)[0].length < 2)
        {
            this.setState({ errorMessage: this.state.phrases.errClaimFor})
            return false
        }

        this.setState({ errorMessage: ""})
        return true;
    }

    // Dynamic sizing for signature canvas
    updateSignatureSize = async () => {

        // Get window dims 
        const w = window.screen.width;

        const partialW = w * 0.9;

        // 80% OR maxSignatureW
        let sigPadWidth = maxSignatureW;

        if (partialW < sigPadWidth)
            sigPadWidth = partialW;

        let sigPadHeight = sigPadWidth / signatureRatio;


        await this.setState({ signatureDims: [sigPadWidth, sigPadHeight] });

    }

    finishClaim = async () => {

        this.props.setLoadingState(true);

        await this.saveSignature()

        if (await this.checkReady())
            this.setState({uploading: true})

        // Form data for images
        const formData = new FormData();

        
        // Add each file 
        if (this.props.newClaimData.images) {
            for (let i=0; i<this.props.newClaimData.images.length ; i++) {
                const fileType = this.props.newClaimData.images[i].type ? this.props.newClaimData.images[i].type : 'png';
                formData.append('files', this.props.newClaimData.images[i].blob, ('eob' + i + '.' + fileType));
            }
        }

        // Add body properties
        formData.set('object', JSON.stringify({
            plan: this.props.newClaimData.plan._id,
            status: 'Pending', 
            for: this.state.claimFor.filter(x => x.length > 0).map(x => x.trim()),
        }));
        formData.set('signature', this.props.newClaimData.signature);

        this.props.load();
        const res = await api.post("claim/create", formData);
        this.props.done();

        if (!res || !res.success) {
            // TODO : Response error

        }

        this.props.history.push('claim-submitted')
        this.props.setLoadingState(false);

    }

    clearSignature = async () => {

        const sigPad = this.signaturePad.current;
        sigPad.clear();

        // Clear signature in app
        await this.props.setSignature(null);
        this.setState({signatureExists: false}, this.checkReady)

    }

    saveSignature = async () => {

        const sigPad = this.signaturePad.current;
        const imgUrl = await sigPad.toDataURL();

        // Send signature to app 
        await this.props.setSignature(imgUrl);
        await this.checkReady();

    }

    editSignature = async () => {

        // Clear in app
        await this.props.setSignature(null);
        await this.checkReady();

    }

    setClaimFor = (value, idx) => {
        let claimFor = [...this.state.claimFor]
        claimFor[idx] = value;

        if (claimFor[claimFor.length-1].length > 0)
            claimFor.push('');
        if (claimFor[claimFor.length - 1].length === 0 && claimFor[claimFor.length - 2].length === 0)
            claimFor.pop();

        this.setState({claimFor}, this.checkReady);
    }

    removeClaimFor = async idx => {
        let claimFor = [...this.state.claimFor];

        if (claimFor.length !== 1) {        
            claimFor.splice(idx, 1);
            await this.setState({claimFor});
        } else {
            await this.setState({claimFor: ['']})
        }

        this.checkReady();
    }

    render = () => {

        if (!this.props.user)
            return null

        return (<div className="container">

            <h1>{this.state.phrases.msgLetsFinish}</h1>
            <h3>{this.state.phrases.msgWhoClaimFor}</h3>

            <div className='claimForContainer'>
                {this.state.claimFor.map((claimFor, claimForIdx) => {
                    return (
                        <div className='claimForInput'>
                            <input
                                style={{outline: 'none'}}
                                placeholder={this.state.phrases.msgTypeName}
                                ref={'claimForInput_'+claimForIdx}
                                value={claimFor}
                                onChange={e => this.setClaimFor(e.target.value, claimForIdx)}
                            />
                            {claimForIdx !== this.state.claimFor.length-1 && <img
                                alt="delete"
                                src='/closeRed.svg'
                                title='Remove Field'
                                className='claimForRemoveBtn clickable'
                                onClick={async () => await this.removeClaimFor(claimForIdx)}
                            />}
                        </div>
                    )
                })}
            </div>

            <p>{this.state.phrases.msgBySigningBelow}</p>

            {/* Awaiting Signature feature below */}            
            <div className='signatureWrapper'>
                <h3 className="my-signature">{this.state.phrases.msgMySignature}</h3>
                <p style={{color: 'rgb(140,140,140)', fontWeight: '600',margin: '15px 0'}}>{this.state.phrases.msgDrawWithFinger}</p>
                <div>
                
                    {/* Signature Pad */}
                    <SignatureCanvas 
                        ref={this.signaturePad}
                        penColor='black'
                        onEnd={() => {
                            this.setState({signatureExists: true}, this.checkReady)
                        }}
                        onBegin={() => {
                            for (let i=0; i<this.state.claimFor.length; i++) {
                                this.refs['claimForInput_'+i].blur();
                            }
                        }}
                        canvasProps = {{width: this.state.signatureDims[0], height: this.state.signatureDims[1], className: 'signatureCanvas'}}
                    />
                    
                    {/* Clear / Save */}

                    
                    <div className='cleardiv' onClick = {this.clearSignature} >
                        {this.state.phrases.btnClear}
                    </div>

                </div>  
            </div>

            {this.state.uploading ? <button 
                className="finish" 
                style={{ width: '80%', marginLeft: '10%' }}
            >{this.state.phrases.msgUploadingDocs}
            </button> : <button 
                className="finish"
                onClick={() => {
                    if (this.state.errorMessage === "")
                        this.finishClaim()
                }}
                style={this.state.errorMessage === ""
                    ? { width: '80%', marginLeft: '10%' }
                    : { width: '80%', marginLeft: '10%', backgroundColor: "#fee", color: "#a33"}
                }
            >
                {this.state.errorMessage === "" ? this.state.phrases.btnFinish : this.state.errorMessage}
            </button>
            }
    
        </div>);
    }

};